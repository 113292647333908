<template>
    <div>
       <main data-aos="fade" class="pb-3">
        <section class="ticket-area site-sec bg-light">
            <div class="site-sec-wrap container-xxl">
                <div class="bg-white my-3 px-3 px-md-5 py-5 border min-vh-100">
                    <div class="">
                        <h2>Payment</h2>
                        <p>choose ticket(s)</p>
                        <label>Currency</label>
                        <select v-model="selectedCurrency" @change="changeCurrency($event)">
                            
                            <option v-for="(currency, c) in currencies" :key="c">{{ currency }}</option>
                        </select>
                    </div>
                    <div v-if="loading" class="spinner-border text-warning" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="alert">
                        <ul v-if="validationErrors">
                            <li v-for="(verrors, field) in errors" :key="field">
                                {{ field }} 
                                
                                <ul>
                                    <li v-for="error in verrors" :key="error.message">
                                        {{ error }}
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div class="row">
                        <div class="col-md-7 col-lg-8 border-bottom pb-3 mb-4">
                            <h4 class="mb-3">Tickets</h4>
                            <form ref="form" novalidate class="needs-validation" v-if="eventInView" >
                                <div class="row g-2 ticket-details" v-if="!seasonTicketPurchase">
                                    <div class="col-12" v-for="(price,k) in eventInView.prices" :key="k">
                                        <div
                                            class="border rounded d-flex justify-content-between align-items-center px-3 py-2">
                                            <div class="me-2">
                                                <strong class="text-nowrap">{{price.pricing_label}} </strong> <span v-if="price.is_free">[free]</span>
                                                <span class="d-block mt-1 text-primary">{{ selectedCurrency }}
                                                    {{(enableOnlyTwoCurrencies && selectedCurrency == secondaryCurrency) ? price.secondary_currency_price: price.price}}</span>
                                            </div>
                                            <div class="w-auto">
                                                <input v-model="cart[k].quantity" size="3" type="number" min="0" step="1" class="form-select" id="inputGroupSelect02" :disabled="isAnyQuantitySelected && cart[k].quantity === 0" />
                                            </div>
                                            <div id="seatSelect">
                                                <a @click.prevent="viewStadium(eventInView.id,price.id,price.pricing_label)"  href="" class="w-100 btn btn-warning btn-lg">Seat Select</a>   
                                            </div>
                                        </div>
                                    </div>                                    
                                   
                                </div>

                                <div class="row g-2 ticket-details" v-if="seasonTicketPurchase">
                                    <div class="col-12" >
                                        <div
                                            class="border rounded d-flex justify-content-between align-items-center px-3 py-2">
                                            <div class="me-2">
                                                <strong class="text-nowrap">{{eventInView.name}} ( {{ eventInView.organiser.name }} )</strong> 
                                                <span class="d-block mt-1 text-primary">{{ selectedCurrency }}
                                                    {{eventInView.amount}}</span>
                                            </div>
                                            <div class="w-auto">
                                                 <input v-model="cart[0].quantity" size="3" type="number" min="0" step="1" class="form-select" id="inputGroupSelect02" /> 
                                                   
                                               
                                            </div>
                                        </div>
                                    </div> 
                                   
                                </div>
                                
                                <h4 class="mt-4 mb-3">Ticket Delivery</h4>
                                <div class="my-3">
                                    <div class="form-check">
                                        <input v-model="sendEmail" id="credit" name="deliveryMethod" type="checkbox"
                                            class="form-check-input" required>
                                        <label class="form-check-label" for="credit">Email</label>
                                    </div>
                                    <div class="form-check">
                                        <input v-model="sendSms" id="debit" name="deliveryMethod" type="checkbox" class="form-check-input"
                                            required>
                                        <label class="form-check-label" for="debit">SMS</label>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col-md-5 col-lg-4 order-md-last mb-4">
                            <h4 class="d-flex justify-content-between align-items-center mb-3">
                                <span class="text-primary">Order Summary</span>
                            </h4>
                            <ul class="list-group mb-3">
                                <li v-for="(cartItem, n) in cart" :key="n" class="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 class="my-0">{{cartItem.priceLabel}}</h6>
                                        <small class="text-muted">{{cartItem.quantity}}</small>
                                    </div>
                                    <span class="text-muted">{{(enableOnlyTwoCurrencies && selectedCurrency == secondaryCurrency) ? cartItem.priceSecondary * cartItem.quantity : cartItem.price * cartItem.quantity}}</span>
                                </li>
                                
                                <li class="list-group-item d-flex justify-content-between bg-light">
                                    <div class="text-success">
                                        <h6 class="my-0">Service Fee</h6>
                                        <small>Subject to change</small>
                                    </div>
                                    <span class="text-success">0</span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between">
                                    <span>Total ({{ selectedCurrency }})</span>
                                    <strong>{{selectedCurrency}} {{cartSum}}</strong>
                                </li>
                            </ul>

                            <form class="card p-2">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Promo code">
                                    <button type="submit" class="btn btn-secondary">Redeem</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="row g-5">
                        <div class="col-md-7 col-lg-8">
                            <h4 class="mb-3">Contact Information</h4>
                            <span class="mb-2" v-if="!isLoggedIn">Continue as guest or <a href="/login">login</a></span>
                            <ValidationObserver v-slot="{ handleSubmit}">
                            <form class="needs-validation" @submit.prevent="handleSubmit(processPayment)">
                                <div class="row g-3">
                                    <div class="col-sm-6">
                                        <label for="firstName" class="form-label">First name</label>
                                        <validation-provider rules="required" v-slot="{ errors }">
                                        <input v-model="firstName" type="text" class="form-control" id="firstName"  
                                            required>                                        
                                        <span style="color:red">{{ errors[0] }}</span>
                                        </validation-provider>
                                    </div>

                                    <div class="col-sm-6">
                                        <label for="lastName" class="form-label">Last name</label>
                                        <validation-provider rules="required" v-slot="{ errors }">
                                        <input v-model="lastName" type="text" class="form-control" id="lastName"  
                                            required>
                                        <span style="color:red">{{ errors[0] }}</span>
                                        </validation-provider>
                                    </div>

                                    <div class="col-12">
                                        <label for="email" class="form-label">Email</label>
                                        <validation-provider rules="email" v-slot="{ errors }">
                                        <input v-model="email" type="email" class="form-control" id="email"
                                            placeholder="you@example.com" required>
                                        <span style="color:red">{{ errors[0] }}</span>
                                        </validation-provider>
                                    </div>
                                    <div class="col-12">
                                        <label for="phone" class="form-label">Phone Number </label>
                                        <!-- <validation-provider rules="required|numeric" v-slot="{ errors }">
                                        <input v-model="phoneNumber" type="text" class="form-control" id="phone"
                                         placeholder="250785432432"   >
                                        <span style="color:red">{{ errors[0] }}</span>
                                        </validation-provider> -->
                                        <vue-tel-input :required="true" v-model="phoneNumber" :preferredCountries="currentCountries" :inputOptions="{showDialCode: true}" :invalidMsg="`invalid phone number`" :validCharactersOnly="true"></vue-tel-input>
                                    </div>
                                </div>

                                <hr class="my-4">


                                <!-- <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="save-info">
                                    <label class="form-check-label" for="save-info">Save this information for next
                                        time</label>
                                </div> -->
                                <div class="form-check">
                                    <input v-model="promotionEvent" type="checkbox" class="form-check-input" id="save-info">
                                    <label class="form-check-label" for="save-info">Receive communication from us and our affiliates?
                                        </label>
                                </div>

                                <hr class="my-4">

                                <h4 class="mb-3">Payment</h4>

                                <div class="my-3" v-if="!enableOnlyTwoCurrencies">
                                    
                                        <input  v-model="paymentGateway" type="radio" value="palm_kash">Rwanda Mobile Money
                                       
                                        <input  v-model="paymentGateway" type="radio"    value="flutterwave">Other Countries and Card
                                        
                                   
                                </div>
                                <div class="my-3" v-else>
                                   
                                    <input  v-model="paymentGateway" type="radio"    value="palm_kash">Mobile
                                    
                               
                            </div>

                            <hr class="my-4">

                            <button :disabled="loading"  class="w-100 btn btn-primary btn-lg" type="submit">Pay</button>    
                           
                            </form>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main> 
    </div>
</template>

<script>
import { VueTelInput } from 'vue-tel-input';
import PalmEvent from '../apis/PalmEvent'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required,email,numeric } from 'vee-validate/dist/rules';
extend('email', email);
extend('numeric', numeric);
extend('required', {
  ...required,
  message: 'This field is required'
});

export default {
    components: {
        ValidationProvider,
        VueTelInput,
        ValidationObserver
    },
    data(){
        return {
            loading: false,
            validationErrors: false,
            errors: [],
            isLoggedIn: false,
            validForm : false,
            eventInView: null,
            cart : [],
            firstName: null,
            lastName: null,
            email: null,
            phoneNumber: '',
            sendSms: false,
            sendEmail: false,
            promotionEvent: false,
            seasonTicketPurchase: false,
            user_id: null,
            paymentGateway: 'palm_kash',
            currencies: ['RWF', 'UGX', 'KES', 'TZS', 'USD'],
            selectedCurrency: 'RWF',
            baseCurrency: 'RWF',
            secondaryCurrency: 'RWF',
            currentRate: 1,
            enableOnlyTwoCurrencies: false,
            reserved_seats: [],
            selectedPriceId: null,
            currentCountries: ['RW', 'CD', 'UG', 'BI']
        }
    },
    methods: {
        updateCart(priceId, quantity){
            let cartItemIndex = this.cart.findIndex((cart) => cart.priceId == priceId);
            if(cartItemIndex != -1) {
                this.cart[cartItemIndex].quantity = quantity
            }
            console.log(this.cart +'.....' +quantity+price);
        },
        changeCurrency() {
            if (!this.enableOnlyTwoCurrencies)
            {
                this.loading = true;
                PalmEvent.getExchangeRate({to:this.selectedCurrency}).then(
                    (result) => {
                        const rate = result.data.rate;
                        //loop through item and change price
                        if (!this.seasonTicketPurchase) {
                            this.eventInView.prices.forEach(element => element.price = Math.round(rate * element.basePrice))
                            this.cart.forEach(element => element.price = Math.round(rate * element.basePrice))
                        }
                        else if (this.seasonTicketPurchase) {
                            this.eventInView.amount = Math.round(rate * this.eventInView.basePrice)
                            this.cart.forEach(element => element.price = Math.round(rate * element.basePrice))
                        }
                        
                        this.loading = false;
                    },
                    (error) => {
                        const resMessage =
                        (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                        console.log(resMessage)
                        this.loading = false
                    }
                )
            }
        },
        fetchEvent(eventId){
          PalmEvent.getEvent(eventId).then(
            (result) => {
                this.eventInView = result.data.event;
                const curr = result.data.currencies;
                if (curr.multi_currency == 'true') {
                    this.enableOnlyTwoCurrencies = true
                    this.currencies = [
                        curr.default_currency, curr.secondary_currency
                    ]
                    this.baseCurrency = curr.default_currency
                    this.selectedCurrency = curr.default_currency
                    this.secondaryCurrency = curr.secondary_currency
                    this.paymentGateway = 'flutterwave'
                }
                //preset cart
                this.eventInView.prices.forEach(element => {
                    let newCartItem = {
                        priceId : element.id,
                        priceLabel: element.pricing_label,
                        price: element.price,
                        basePrice: element.price,
                        priceSecondary: element.secondary_currency_price,
                        quantity: 0
                    }
                    element.basePrice = element.price
                    this.cart.push(newCartItem);
                    
                });
                if (this.selectedPriceId != null)
                {
                    this.updateCart(this.selectedPriceId, this.reserved_seats.length)
                }

                
                
            },
            (error) => {
                const resMessage =
                    (error.response &&
                      error.response.data &&
                      error.response.data.message) ||
                    error.message ||
                    error.toString();
              console.log(resMessage)
            }
        )
      },
      processPayment(){
          this.loading = true;

          
          
          if(this.sendSms == false && this.sendEmail == false)
          {
              alert("please choose atleast one ticket delivery method (SMS OR EMAIL)")
              this.loading = false;
              return;
          }
           if (this.paymentGateway == 'palm_kash' && this.selectedCurrency == 'USD')
           {
             alert("your selection of payment method does not support USD. change currency")
               this.loading = false;
               return;
           }
          let payload = {
              cart : this.cart,
              send_sms: this.sendSms,
              send_email: this.sendEmail,
              first_name: this.firstName,
              last_name: this.lastName,
              email: this.email,
              phone_number: this.phoneNumber.replace(/[\s~`!@#$%^&*(){}\[\];:"'<,.>?\/\\|_+=-]/g, ''),
              opt_in_communication: this.promotionEvent,
              is_season_ticket: this.seasonTicketPurchase,
              user_id: this.user_id,
              payment_gateway: this.paymentGateway,
              selected_currency: this.selectedCurrency,
              reserved_seats: this.reserved_seats,
              
          }
          PalmEvent.buyTickets(payload).then(
              (result) => {
                  this.loading= false;
                  if(result.data.message !== 'success')
                  {
                    this.$toast.error(result.data,{
                        duration: 10000
                    });  
                  }
                  else{
                      //go to ticket processing page
                      const dataLink = result.data.data_link
                      if (dataLink != undefined) {
                        window.location.href = dataLink;
                        return;
                      }
                      const transactionId = result.data.transactionId;
                      this.$router.push({ name: "PaymentProcessing",params: {id: transactionId} });
                  }
              },
              (error) => {
                 const resMessage =
                    (error.response &&
                      error.response.data &&
                      error.response.data.errors) ||
                    error.message ||
                    error.toString();
                    //this.errors = resMessage
                    console.log(resMessage) 
                    this.loading = false
                    this.errors = resMessage
                    this.$toast.error(resMessage,{
                        duration: 10000
                    });
              }
          )
        
      },
      viewStadium(eventId, priceId, label){
         this.$router.push({ name: "Amahoro",params: {eventId: eventId, priceId: priceId, type:label} }); 
      }
    },
    computed: {        
        cartSum() {
            if (this.enableOnlyTwoCurrencies && this.selectedCurrency == this.secondaryCurrency)
            {
                return this.cart.reduce(
                (acc, item) => acc + parseFloat(item.priceSecondary)*parseInt(item.quantity),
                0
                );
            }
            else {
                return this.cart.reduce(
                    (acc, item) => acc + parseFloat(item.price)*parseInt(item.quantity),
                    0
                );
            }
        },
        isAnyQuantitySelected() {
          return this.cart.some(item => item.quantity > 0);
        }
    },
    created(){
        let isLoggedIn = !!localStorage.getItem("token");
      if(isLoggedIn){
        let currentUser = JSON.parse(localStorage.getItem('user'));
        this.isLoggedIn = true;
        this.user_id = currentUser.id 
        let fullName = currentUser.name
        
        this.firstName = fullName.toString().split(" ")[0]
        this.lastName = fullName.toString().split(" ")[1]
        this.phoneNumber = currentUser.phone_number
        this.email = currentUser.email
      }
        let eventData = this.$route.params.pEvent
        let eventId = this.$route.params.id
        let priceId = this.$route.params.priceId
        let reservedSeats = this.$route.params.reserved_seats
        const isSeasonTicket = this.$route.params.isSeasonTicket
        let sPrice = this.$route.params.sPrice

        if(priceId && reservedSeats){
            this.selectedPriceId = priceId
            this.reserved_seats = reservedSeats
            //this.updateCart(priceId, reservedSeats.length);
        }

        if(eventData){
            this.eventInView = eventData;
            
            //preset cart
            // this.eventInView.prices.forEach(element => {
            //     let newCartItem = {
            //         priceId : element.id,
            //         priceLabel: element.pricing_label,
            //         price: element.price,
            //         basePrice: element.price,
            //         quantity: 0
            //     }
            //     this.cart.push(newCartItem);
            // });
        } 
        if (isSeasonTicket != undefined) {
            this.eventInView = sPrice
            this.seasonTicketPurchase = true
            this.eventInView.basePrice = sPrice.amount
            let newCartItem = {
                priceId : sPrice.id,
                priceLabel: sPrice.name,
                price: sPrice.amount,
                basePrice: sPrice.amount,
                quantity: 0,
                is_season_ticket: 1
            }
            this.cart.push(newCartItem);
        }
        else {
            this.isLoading = true
            this.fetchEvent(eventId);
        }
        
    }
}
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>
